import React from 'react';

import Layout from '../components/Layout';

const TermsAndConditions = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Terms and Conditions</h2>
      </header>
      <section className="wrapper style4">
        <div className="inner">

          <h3>Introduction</h3>
          <p>
            These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Ruflix accessible at www.ruflix.com.</p>
          <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>
          <p>Minors or people below 18 years old are not allowed to use this Website.</p>
          <h3>Intellectual Property Rights</h3>
          <p>Ruflix and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
          <p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>
          <h3>Restrictions</h3>
          <p>You are specifically restricted from all of the following:</p>
          <ul>
            <li>publishing any Website material in any other media;</li>
            <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
            <li>publicly performing and/or showing any Website material;</li>
            <li>using this Website in any way that is or may be damaging to this Website;</li>
            <li>using this Website in any way that impacts user access to this Website;</li>
            <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
          </ul>
          <h3>No warranties</h3>
          <p>This Website is provided “as is,” with all faults, and Ruflix expresses no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</p>
          <h3>Limitation of liability</h3>
          <p>Ruflix, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>
          <h3>Severability</h3>
          <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
          <h3>Variation of Terms</h3>
          <p>Ruflix is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>
          <h3>Entire Agreement</h3>
          <p>These Terms constitute the entire agreement between Ruflix and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>
          <h3>Governing Law & Jurisdiction</h3>
          <p>These Terms will be governed by and interpreted in accordance with the laws of Russian Federation, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Russian Federation for the resolution of any disputes.</p>
        </div>
      </section>
    </article>
  </Layout>
);

export default TermsAndConditions;
